:root {
  --aux-black: theme('colors.aux.black');
  --aux-gray-light: theme('colors.aux.gray.light');
  --aux-gray-light-3: theme('colors.aux.gray.light-3');
  --aux-gray-dark: theme('colors.aux.gray.dark');
  --aux-gray-dark-100: theme('colors.aux.gray.dark-100');
  --aux-gray-darkest: theme('colors.aux.gray.darkest');
  --aux-green: theme('colors.aux.green.DEFAULT');
  --aux-green-secondary: theme('colors.aux.green.secondary');
  --aux-green-dark: theme('colors.aux.green.dark');
  --aux-green-hover: theme('colors.aux.green.hover');
  --aux-blue-light: theme('colors.aux.blue.light');
  --aux-blue-light-200: theme('colors.aux.blue.light-200');
  --aux-blue-light-50: theme('colors.aux.blue.light-50');
  --aux-blue: theme('colors.aux.blue.DEFAULT');
  --aux-blue-500: theme('colors.aux.blue.500');
  --aux-blue-hover: theme('colors.aux.blue.hover');
  --aux-blue-dark: theme('colors.aux.blue.dark');
  --aux-warn: theme('colors.aux.warn');
  --aux-error: theme('colors.aux.error');
  --aux-red-dark: theme('colors.aux.red.dark');
  --aux-red-hover: theme('colors.aux.red.hover');
  --aux-red-900: theme('colors.aux.red.900');
  --aux-green-500: theme('colors.aux.green.500');
  --aux-purple: theme('colors.aux.purple');

  --text: var(--aux-gray-darkest);
  /*--text-secondary: var(--color-primary-100);*/

  --bg: var(--aux-gray-light);
  /*--bg-secondary: var(--color-primary-800);*/
}
